import React from 'react';
import LayoutComponent from '../../../components/documentation-layout/Layout';

const AssignJob = () => {
  return (
    <LayoutComponent>
      <h3>Assign a Job or Workflow to a Test Cycle</h3>
      <div>
        <p>
          You can assign a job or workflow to a test cycle to automate the execution process. Follow these steps:
        </p>
        <ul>
          <li>
            Navigate to the <strong>Test Cycle</strong> view.
          </li>
          <li>
            Ensure the test cycle <strong>Type</strong> is set to <strong>Automation</strong>. Only then will you be able to assign a job or workflow.
          </li>
          <li>
            Select the <strong>Job or Workflow</strong> you want to assign, based on the execution tool you configured (Jenkins or GitHub Actions). You'll see all the jobs or workflows available from your Jenkins or GitHub repository.
          </li>
          <li>
            Click <strong>Save</strong> to confirm the assignment.
          </li>
        </ul>
        <p>
          You can also assign a job or workflow during the test cycle creation process.
        </p>
      </div>
    </LayoutComponent>
  );
};

export default AssignJob;
